<template>
    <div class="center">
        <!-- 主体 -->
        <main class="mt-10">
            <van-button class="logout" @click="logout" block round type="info" >退出账户</van-button>
        </main>

        <Footer page="my" />
    </div>
</template>

<script>
    import Footer from "@/components/Footer";
    import { mapGetters } from "vuex";
    import { Toast } from 'vant';
    export default {
        components: {
            Footer
        },

        computed: {
            ...mapGetters(["userId", "isLogin"])
        },

        data() {
            return {
                userInfo: {
                    nickname:null,
                    mobile:null,
                },
            }
        },

        methods: {
            edit() {
                this.$router.push("edit");
            },
            logout() {
                this.$store.commit("updateLogin", false);
                localStorage.removeItem('openId');
                localStorage.removeItem('userInfo');
                Toast.fail('退出账户成功');
            }
        },
        create() {
        },
        mounted() {
        }
    };
</script>

<style lang="less" scoped>
    /* 本页公共样式 */
    .gray {
        color: #797d82;
    }

    .bott0 {
        bottom: 0;
    }

    .orange {
        color: #ff5f16
    }

    .yellow {
        color: #ffb232
    }

    .bd-gray {
        border-bottom: 1px solid #f5f5f5;
    }

    .login_form {
        margin-top: 46px;
    }

    // 用户名和头像
    .users {
        top: 55px;
        left: 22px;
        position: absolute;

        // 头像
        .imege {
            height: 65px;
            border-radius: 50%;
        }

        .fff {
            font-size: 22px;
        }
    }
    // 头像
    .imege {
        height: 65px;
        border-radius: 50%;
    }

    // 导航
    .nav {
        height: 75px;

        .iconfont {
            font-weight: bold;
        }

        .cit {
            height: 26px;
        }
    }

    .logout {
        width: 80%;
        margin: 50px auto 0;
    }

    // 主体
    main {
        .list {
            height: 50px;

            .image {
                height: 20px;
            }
        }

        .foun {
            font-size: 12px;
        }

    }

    /* 底部 */
    .footer {
        height: 50px;
    }
</style>